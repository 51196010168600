<template>
  <div class="app-container user-container">
    <el-form inline :model="listQuery" label-width="100px">
      <el-form-item label="结算时间：">
        <el-date-picker
          v-model="listQuery.created_arr"
          type="daterange"
          align="right"
          value-format="yyyy-MM-dd"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="fetchData(1)"
          :picker-options="pickerOptions">
        </el-date-picker>
      </el-form-item>
      <el-form-item label=" ">
        <el-button @click="fetchData(1)" type="primary">搜索</el-button>
        <el-button @click="onReset" type="default">重置</el-button>
      </el-form-item>
    </el-form>

    <!-- 表格区域  -->
    <el-table
      :data="list"
      v-loading="loading"
      border
      fit
      hightlight-current-row
    >
      <el-table-column label="申请单数量" min-width="150" align="center">
        <template slot-scope="{ row }">
          <span>{{ row.orders.split(',').length }} 个</span>
        </template>
      </el-table-column>
      <el-table-column label="结算费用" min-width="120" align="center">
        <template slot-scope="{ row }">
          <span>{{ +(row.price || 0) }} SEK</span>
        </template>
      </el-table-column>
      <el-table-column label="结算时间" min-width="180" align="center" prop="created_time" />
      <el-table-column label="操作" width="150" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" :href="`/rest/order/log/export/${row.id}?token=${token}&type=2`">单据导出</el-link>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :current-page="listQuery.page"
      :page-size="listQuery.page_size"
      :total="total"
      style="padding: 32px 0;"
      layout="total, prev, pager, next, jumper"
      @current-change="fetchData"/>
  </div>
</template>

<script type="text/javascript">
import type from '@/api/type'
import api from '@/api/order'
import dipm from '@/api/dipm'
import { mapGetters } from 'vuex'
import { download } from '@/utils/index'
const listQuery = {
  page: 1,
  page_size: 10,
  dipm_id: '',
}
export default {
  data() {
    return {
      listQuery: { ...listQuery },
      loading: false,
      list: [],
      total: 0,
      dipmList: [],

      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  computed: {
    ...mapGetters(['isAdmin', 'token'])
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData(page = 1) {
      this.listQuery.page = page
      this.loading = true
      api.deliveryFeeLog({
        ...this.listQuery,
      })
      .then(res => {
        this.total = res.total
        this.list = res.list || []
        this.loading = false
      })
    },
    onReset() {
      this.listQuery = { ...listQuery }
      this.fetchData()
    },
  }
}
</script>
